import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { logo } from '../../../assets/img';
import { Button, Input, Image } from '../../shared';
import { createDataBaseInIndexedDb, popup, setLocalStorageItem } from '../../../utils';
import { useDebounce } from '../../../hooks';
import {
  VALIDATION,
  ROUTES,
  BUTTON,
  POPUP_TYPE,
  DB,
  LOCAL_STORAGE,
  FORM_FIELD,
  TEXT,
} from '../../../constants';
import { EyeIcon, OpenEyeIcon } from '../../../assets/icons';
import { warehouseLogin } from '../../../service';

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isFormFocused, setIsFormFocused] = useState(false);

  const login = useDebounce((values, setSubmitting, resetForm) => {
    warehouseLogin({
      data: { db: DB, ...values },
    })
      .then((response) => {
        if (response.data.length === 0) {
          setSubmitting(false);
          dispatch(setLoaderVisibility(false));
          resetForm();
          popup(POPUP_TYPE.ERR, VALIDATION.CREDENTIAL_MISMATCH);
          return navigate(ROUTES.LOGIN);
        }
        setSubmitting(false);
        dispatch(setLoaderVisibility(false));
        createDataBaseInIndexedDb();
        setLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN, response.data.api_key);
        setLocalStorageItem(LOCAL_STORAGE.ALLOWED_APP, response.data.allowed_apps);
        setLocalStorageItem(LOCAL_STORAGE.TAGS, response.data.tags);
        setLocalStorageItem(LOCAL_STORAGE.USERNAME, response.data.user_name);
        navigate(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        setSubmitting(false);
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.form-content') === null)
        setTimeout(() => {
          setIsFormFocused(false);
        }, 100);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validateForm = () => setIsFormFocused(true);

  return (
    <Formik
      className="col-xl-7 col-lg-10 col-11"
      initialValues={{ login: '', password: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.login) {
          errors.login = 'Required';
        }
        if (!values.password) {
          errors.password = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(setLoaderVisibility(true));
        login(values, setSubmitting, resetForm);
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty,
        setTouched,
      }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="text-center">
            <Image src={logo} alt="logo" width="277" height="100" className="form-logo" />
          </div>
          <div
            className="form-content flex-column d-flex align-items-center justify-content-center"
            onFocus={() => validateForm()}>
            <h1 className="title text-center">{}</h1>
            <div className="w-100 input-area">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {FORM_FIELD.EMAIL}
              </label>

              <div className="position-relative">
                <Input
                  className={`form-control ${isFormFocused && errors.login ? 'border border-danger-subtle border-1' : ''}`}
                  type="text"
                  name="login"
                  onChange={(e) => {
                    !touched.login && setTouched({ ...touched, login: true });
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.login}
                />
                {errors.login && touched.login && <p className="form-label">{errors.login}</p>}
              </div>
            </div>
            <div className="w-100 input-area">
              <label htmlFor="exampleInputPassword1" className="form-label">
                {FORM_FIELD.PASSWORD}
              </label>
              <div className="position-relative">
                <Input
                  type={passwordVisible ? 'text' : 'password'}
                  className={`form-control ${isFormFocused && errors.password && touched.password ? 'border border-danger-subtle border-1' : ''}`}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {errors.password && touched.password && (
                  <p className="form-label">{errors.password}</p>
                )}
                <Button
                  type="button"
                  clickHandler={() => setPasswordVisible((visible) => !visible)}
                  className="eye-icon top-12px m-0 border-0 position-absolute"
                  id="ajx">
                  {passwordVisible ? <OpenEyeIcon width={16} height={16} /> : <EyeIcon />}
                </Button>
              </div>
            </div>
            <div className="w-100">
              <Button
                disabled={isSubmitting || !dirty || errors.login || errors.password}
                className="login-blue-btn w-100 d-flex align-items-center justify-content-center"
                type="submit">
                {BUTTON.SignIn}
              </Button>
            </div>
            <div className="w-100">
              <p className="login-bottom-txt">
                {TEXT.NO_ACCOUNT} <span>{FORM_FIELD.SIGN_UP}</span>
              </p>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
