import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseDelivery = async ({ data = {} }) => {
  const url = API.WAREHOUSE_DELIVERY;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};



