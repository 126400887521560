import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../constants';
import {
  DashboardContent,
  Deliveries,
  PelletDetails,
  PelletList,
  DeliveryDetail,
  Shipment,
  ShipmentDetail,
  Transfers,
  TransferSelector,
  Pelleting,
} from '../pages';

const RenderDashboard = (selectedTab) => {
  const url = selectedTab.split('/');
  switch (selectedTab) {
    case ROUTES.DASHBOARD:
      return <DashboardContent />;
    case ROUTES.SHIPMENT:
      return <Shipment />;
    case `${ROUTES.SHIPMENT_DETAIL}/${url[2]}`:
      return <ShipmentDetail />;
    case ROUTES.PELLETING:
      return <PelletList />;
    case `${ROUTES.PELLETING}/${url[2]}`:
      return <PelletDetails />;
    case `${ROUTES.PELLET_DETAILS}/${url[2]}`:
      return <Pelleting />;
    case ROUTES.TRANSFERS:
      return <Transfers />;
    case `${ROUTES.TRANSFER_DETAIL}/${url[2]}`:
      return <TransferSelector />;
    case ROUTES.DELIVERIES:
      return <Deliveries />;
    case `${ROUTES.DELIVERY_DETAIL}/${url[2]}`:
      return <DeliveryDetail />;
    default:
      return <DashboardContent />;
  }
};

export const ContentBox = ({ currentRoute }) => {
  return <div className="main-content">{RenderDashboard(currentRoute)}</div>;
};

ContentBox.propTypes = {
  currentRoute: PropTypes.string.isRequired,
};
