import React, { useEffect, useState } from 'react';
import { PalletIcon, ScanIcon } from '../../../assets/icons';
import { Scanner } from '../../common';
import { popup } from '../../../utils';
import { POPUP_TYPE } from '../../../constants';

// import { UpdateDataInIndexDB } from '../../../utils';
export const DetailsBoxPallet = ({
   doneStatus,
  packageCount,
  palletsCount,
  scanData,
  setScanData,
  pallet,
  setCount,
  step,
  setPallet,
  selectedPallet,
  setSelectedPallet,
  setStep,
}) => {
  const [scanState, setScanState] = useState(0);

  const handleScanStart = () => {
    scanState === 0 ? setScanState(1) : setScanState(0);
    setScanData('');
  };
  const handleTransfer = () => {
    if (selectedPallet) {
      if (
        pallet
          .filter((pal) => pal.pallet_id === selectedPallet.pallet_id)[0]
          .packages.includes(scanData)
      ) {
        popup(POPUP_TYPE.ERR, 'Already Scanned');
        setScanState(1);
        return;
      }
      setPallet(
        (pre) =>
          pre.map((pallet) => {
            return pallet.pallet_id === selectedPallet.pallet_id
              ? { ...pallet, packages: [...pallet.packages, scanData] }
              : pallet;
          }),
        setSelectedPallet({}),

        setScanState(1),
        setScanData(''),
      );
    } else {
      setSelectedPallet({}), setScanState(1), setScanData('');
    }
  };
  useEffect(() => {
    let count = pallet.reduce((acc, cum) => (acc += cum.packages.length), 0);
    setCount(count);
    if (count === packageCount) {
      setStep(1);
    }

    if (pallet.length && pallet.every((blp) => blp?.verified === true)) {
      setStep(2);
    }
    // if (pallet?.length) {
    //   UpdateDataInIndexDB('PalletData', {
    //     id: palletData.picking_id,
    //     package_id: palletData.picking_id,
    //     pallet,
    //   });
    // }
  }, [pallet]);

  useEffect(() => {
    if (scanData && selectedPallet) {
      setScanState(2);
    }
  }, [scanData]);
  return (
    <div className="bg-white top-data-box mb-3 py-3 px-4">
      <div className="row">
        <div className="col-xl-7 col-12">
          <div className="row mt-2">
            <div className="col-4  mb-4">Box Count</div>
            <div className=" col-5 mb-4">{packageCount}</div>
            <div className=" col-4 mb-4">Required Pallets</div>
            <div className=" col-5 mb-4">{palletsCount}</div>
          </div>
        </div>
        <div className="col-xl-5 col-12">
          {step === 1 || step === 2 || doneStatus || (
            <div className="d-flex align-items-center justify-content-start  ">
              <div className="btn-set-pellet h-50 flex-column d-flex align-items-center  me-5">
                <div
                  onClick={handleScanStart}
                  className="white-btn w-100 d-flex align-items-center justify-content-center px-4 py-2">
                  {scanState === 0 ? 'Scan Box' : 'Stop Scan'}
                  <ScanIcon />
                </div>
                <div
                  onClick={handleTransfer}
                  className="blue-btn w-100 d-flex align-items-center justify-content-center px-4 py-2">
                  {selectedPallet ? 'Confirm Pallet Transfer' : 'Scan Again'}
                </div>
              </div>
              {scanState === 1 ? (
                <Scanner setScanData={setScanData} />
              ) : scanState === 2 ? (
                <InfoPallet pallet={pallet} selectedPallet={selectedPallet} />
              ) : (
                <span>{scanData}</span>
              )}
            </div>
          )}
          {step === 1 && (
            <div className="border bg-dark-subtle d-flex w-25 align-items-center justify-content-center px-2 py-2">
              Verification Step
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const InfoPallet = ({ pallet, selectedPallet }) => {
  return (
    <>
      {selectedPallet ? (
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <div className="pellet-color-box flex-column h-50 d-flex align-items-center ">
            <div className="box-top h-25 blue-box ">
              <h4 className="text-center ">{selectedPallet.pallet_name}</h4>
            </div>
            <div className="box-bottom h-25 pallet-info dark-blue d-flex align-items-center justify-content-between">
              <div>Status</div>
              <div className="">{`${pallet.filter((pal) => selectedPallet.pallet_id === pal.pallet_id)[0].packages.length}/${selectedPallet.packages_count}`}</div>
              <div>
                <PalletIcon />
              </div>
            </div>
          </div>
        </div>
      ) : (
        'Wrong BLP Scanned'
      )}
    </>
  );
};
