export const randomNum = (max, min) => {
  let rand = Math.floor(Math.random() * (max - min + 1) + min);
  return rand;
};

export const randomDate = () => {
  const start = new Date(2023, 1, 1);
  const end = new Date();
  const dateGen = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  };
  return convertDate(dateGen(start, end));
};

export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? '0' + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

export const getSortingArray = ({ column, data, compiler }) => {
  if (Array.isArray(data) && data.length) {
    let newData = compiler(data);
    let dataField = column.dataField[0];
    let valueArray = newData
      .map((obj) => obj[dataField])
      .reduce((acc, val) => {
        if (!acc.includes(val[0].toUpperCase() + val.slice(1).replaceAll('_', ' '))) {
          acc.push(val[0].toUpperCase() + val.slice(1).replaceAll('_', ' '));
        }
        return acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : 1));
    return valueArray;
  }
};
