import React from 'react';
import { ActionBox } from '../components/pages/transfers/ActionBox';
import { CheckUncheckTransfer } from '../components/pages/transfers/TransfersDetail';
import { CheckUncheckDelivery } from '../components/pages/deliveries/DeliveryDetail';
import { CheckUncheckPalletTransfer } from '../components/pages/transfers/TrasfersDetailPalletTransfer';

// Warehouse
export const SHIPMENT_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_no'],
    sort: true,
  },
  {
    name: 'Partner Name',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['partner_name'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'DONE' || status === 'ASSIGNED') {
        statusClass = 'received';
      } else if (status === 'Waiting for Dispatch') {
        statusClass = 'waiting';
      } else if (status === 'In Transit') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
    sort: true,
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'DONE' || status === 'CONFIRMED') {
        statusClass = 'received';
      } else if (status === 'WAITING' || status === 'DRAFT') {
        statusClass = 'waiting';
      } else if (status === 'ASSIGNED') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const TRANSFER_DETAIL_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['move_line_id', 'checked'],
    customFormatter: ({ checked, move_line_id, setReload }) => {
      return (
        <CheckUncheckTransfer checked={checked} move_line_id={move_line_id} setReload={setReload} />
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blpName', 'scan_picked', 'done_quantity', 'reserve_quantity', 'move_line_id'],
    customFormatter: ({
      blpName,
      scan_picked,
      done_quantity,
      reserve_quantity,
      move_line_id,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBox
          blpName={blpName}
          move_line_id={move_line_id}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];
export const TRANSFER_DETAIL_PALLET_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Pick From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['checked'],
    customFormatter: ({ checked, setReload }) => {
      return <CheckUncheckPalletTransfer checked={checked} setReload={setReload} />;
    },
  },
];

export const DELIVERY_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Reference',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reference'],
    sort: true,
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'Scheduled Date',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['scheduled_date'],
  },
  {
    name: 'Source Document',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['source_document'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'CONFIRMED') {
        statusClass = 'received';
      } else if (status === 'WAITING' || status === 'CANCEL' || status === 'DRAFT') {
        statusClass = 'waiting';
      } else if (status === 'ASSIGNED' || status === 'IN_PROGRESS') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const DELIVERY_OPERATIONS_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Demand',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['demand'],
  },
  {
    name: 'Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['quantity'],
  },
  {
    name: 'Unit',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['unit'],
  },
];

export const DELIVERY_DETAIL_OPERATION_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'From',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['from'],
  },
  {
    name: 'To',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['to'],
  },
  {
    name: 'BLP',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['blpName'],
  },
  {
    name: 'Reserve Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['reserve_quantity'],
  },
  {
    name: 'Done Quantity',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['done_quantity'],
  },
  {
    name: 'Status',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['checked', 'move_line_id'],
    customFormatter: ({ checked, move_line_id, setReload }) => {
      return (
        <CheckUncheckDelivery checked={checked} move_line_id={move_line_id} setReload={setReload} />
      );
    },
  },
  {
    name: 'Action',
    classNameTH: '',
    classNameTD: '',
    isCustom: true,
    dataField: ['blpName', 'scan_picked', 'done_quantity', 'reserve_quantity', 'move_line_id'],
    customFormatter: ({
      blpName,
      move_line_id,
      scan_picked,
      done_quantity,
      reserve_quantity,
      popup,
      setPopUp,
      setPopUpData,
    }) => {
      return (
        <ActionBox
          move_line_id={move_line_id}
          blpName={blpName}
          scan_picked={scan_picked}
          done_quantity={done_quantity}
          reserve_quantity={reserve_quantity}
          popup={popup}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      );
    },
  },
];

export const PALLETING_COLUMN = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Shipment No.',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['shipment_id'],
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pellets'],
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['packages'],
  },
  {
    name: 'Expected Date/Time',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['expected_date'],
  },
  {
    name: 'Status',
    classNameTH: 'option-td',
    isCustom: true,
    classNameTD: 'option-td',
    dataField: ['status'],
    customFormatter: ({ status }) => {
      let statusClass;
      if (status === 'done') {
        statusClass = 'received';
      } else if (status === 'assigned') {
        statusClass = 'waiting';
      } else if (status === 'draft') {
        statusClass = 'dispatched';
      }
      return (
        <div className={`pills col-${statusClass} d-flex ps-2 justify-content-center status-pills`}>
          <p>{status}</p>
        </div>
      );
    },
  },
];

export const RECEPTION_COLUMNS = [
  {
    name: '',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: [''],
  },
  {
    name: 'Product',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['product'],
  },
  {
    name: 'Package Type',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['package_type'],
  },
  {
    name: 'Package Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['total_packages'],
  },
  {
    name: 'Pallets Count',
    classNameTH: 'option-td',
    classNameTD: 'option-td',
    dataField: ['pallets_required'],
  },
];
