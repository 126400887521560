import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchBar, Table, TitleBar, DropDown } from '../../common';
import { useDebounce } from '../../../hooks';
import { getPalletList } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, palletListCompiler, getSortingArray } from '../../../utils';
import { Button } from '../../shared';
import { ROUTES, PALLETING_COLUMN, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';

export const PalletList = () => {
  const [palletData, setPalletData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort2, setSort2] = useState(PALLETING_COLUMN[4].name);
  const [sortingArray2, setSortingArray2] = useState([]);
  const dispatch = useDispatch();

  const getPalletingInfo = useDebounce((data = {}) => {
    getPalletList({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPalletData(response.data);
          if (!sortingArray2.includes(sort2)) {
            setSortingArray2(
              getSortingArray({
                column: PALLETING_COLUMN[4],
                data: response.data,
                compiler: palletListCompiler,
              }),
            );
          }
        } else {
          setPalletData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPalletingInfo(data);
    } else if (!searchTerm && !Array.isArray(palletData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getPalletingInfo(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPalletingInfo();
  }, []);
  const resetFilter = () => {
    setSort2(PALLETING_COLUMN[2].name);
    dispatch(setLoaderVisibility(true));
    getPalletingInfo();
  };
  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray2.length > 1) {
      data = {
        state: `${sortingArray2.includes(sort2) ? sort2.toLowerCase() : ''}`,
      };
      getPalletingInfo(data);
    }
  }, [sort2]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PALLETING, route: ROUTES.PALLETING }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2}></DropDown>
          {sortingArray2.includes(sort2) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3"
            >
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(palletData) ? (
        <Table
          columns={PALLETING_COLUMN}
          initialData={palletData}
          PAGE_SIZE={10}
          colFilter={false}
          to={ROUTES.PALLET_DETAILS}
          compiler={{ use: true, tool: palletListCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{palletData}</h5>
        </div>
      )}
    </>
  );
};
