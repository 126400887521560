import * as React from 'react';
const TransferLogo = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M72.0988 23.8253L56.2735 8V13.1675H21.1655C13.906 13.1675 8 19.0736 8 26.333V46.3373L11.4457 41.1687C14.2366 36.9824 18.9066 34.4831 23.9379 34.4831H56.2735V39.6506L72.0988 23.8253Z"
      fill="#4B5564"
    />
    <path
      d="M68.7518 38.8313C65.961 43.0176 61.291 45.5169 56.2597 45.5169H23.9241V40.3494L8.09875 56.1747L23.9241 72V66.8325H59.0321C66.2915 66.8325 72.1976 60.9264 72.1976 53.667V33.6627L68.7518 38.8313Z"
      fill="#4B5564"
    />
  </svg>
);
export default TransferLogo;
