import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar, PopUpBox, Scanner } from '../../common';
import { TITLES, ROUTES, POPUP_TYPE, POPUP_MSG, BUTTON } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailsBoxPellet } from './DetailsBoxPellet';
import { useDebounce } from '../../../hooks';
import { pelletPackages, packagePlacing, palletTransfer } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { Button } from '../../shared';
import { ZoomInOut } from './ZoomInOut';
export const Pelleting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation(location.state);
  const [count, setCount] = useState(0);
  const [packageData, setPackageData] = useState([]);
  const [pelletData, setPelletData] = useState({});
  const [scanData, setScanData] = useState('');
  const [selectedPellet, setSelectedPellet] = useState({});
  const [popUp, setPopUp] = useState(false);
  const [reload, setReload] = useState(false);
  const [packageScanned, setPackageScanned] = useState('');
  const [gridData, setGridData] = useState({
    rows: 0,
    pellets: [],
    pellets_count: 0,
  });

  const assignPallets = (packages, count) => {
    if (packages.every((obj) => obj.state === 'moved')) {
      navigate(ROUTES.PELLETING);
      return;
    }
    const state = ['assigned', 'verified', 'placed'];
    let availablePackages = packages.filter((pack) => state.includes(pack.state));
    let unique = [...new Set(availablePackages.map((pack) => pack.pallet_id?.id))];

    const data = unique.slice(0, count).map((id) => {
      return {
        pellet_id: id,
        packages: packages
          .filter(
            (pack) =>
              pack.pallet_id.id === id && (pack.state === 'placed' || pack.state === 'verified'),
          )
          .map((pack) => {
            return { id: pack.id, verified: pack.state, blp: pack.package_id.name };
          }),
        packages_count: packages.filter((pack) => pack.pallet_id.id == id).length,
        pellet_name: packages.filter((pack) => pack.pallet_id.id == id)[0].pallet_id.name,
        SKU: packages.filter((pack) => pack.pallet_id.id == id)[0].product_id.default_code, // here the issue
        verified: packages
          .filter((pack) => pack.pallet_id.id === id)
          .every((pack) => pack.state === 'verified'),
      };
    });
    setPelletData({
      packageCount: packages.length,
      palletsCount: unique.length,
      pellets: data,
    });
    setCount(packages.filter((pack) => pack.state !== 'assigned').length);
  };

  const getPelletPackages = useDebounce(() => {
    pelletPackages({
      data: { shipment_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setGridData(response.data.grid_data);
        if (response.data.pallets.length) {
          setPackageData(response.data.pallets);
          assignPallets(response.data.pallets, response.data.grid_data.pellets_count);
          setReload(false);
        }
      })

      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getPelletPackages();
      return;
    }
    dispatch(setLoaderVisibility(true));
    getPelletPackages();
  }, [reload]);
  console.log(selectedPellet);
  useEffect(() => {
    if (scanData) {
      if (scanData.startsWith('000084')) {
        setSelectedPellet({});
        setPackageScanned(scanData);
        const pallet = packageData?.filter((pack) => pack.package_id.name === scanData);
        if (pallet.length > 0 && pallet[0].state != 'moved') {
          if (
            pelletData.pellets?.filter((pal) => pal.pellet_id == pallet[0].pallet_id.id).length > 0
          ) {
            setSelectedPellet(
              pelletData.pellets?.filter((pal) => pal.pellet_id == pallet[0].pallet_id.id)[0],
            );
          } else {
            popup(POPUP_TYPE.ERR, POPUP_MSG.PALLET_NOT_INCLUDED);
            setScanData('');
          }
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_PACKAGE_SCANNED);
          setScanData('');
        }
      } else if (scanData.startsWith('Pallet')) {
        if (packageScanned) {
          const pallet = packageData.filter((pack) => pack?.package_id?.name === packageScanned)[0];
          if (pallet.state === 'assigned') {
            if (pallet.pallet_id.name === scanData) {
              dispatch(setLoaderVisibility(true)),
                packagePlacing({
                  data: {
                    pallet_process_ids: pallet.id,
                    type: 'placed',
                  },
                })
                  .then((response) => {
                    setReload(true);
                    setPackageScanned('');
                    popup(
                      POPUP_TYPE.SUCCESS,
                      POPUP_MSG.PACKAGE_PLACED(
                        packageScanned,
                        response.data[0].state,
                        pallet.pallet_id.name,
                      ),
                    );
                    setSelectedPellet({});
                    setScanData('');
                  })
                  .catch((error) => {
                    dispatch(setLoaderVisibility(false));
                    popup(POPUP_TYPE.ERR, error.message);
                  });
            } else {
              popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_PALLET_SCANNED);
              setScanData('');
            }
          } else if (pallet.state === 'placed') {
            popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_PLACED), setSelectedPellet({});
            setScanData('');
            setPackageScanned('');
          } else {
            popup(POPUP_TYPE.ERR, POPUP_MSG.PLACED_AND_VERIFIED);
            setSelectedPellet({});
            setScanData('');
            setPackageScanned('');
          }
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.SCAN_PACKAGE_FIRST);
        }
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
        setScanData('');
      }
    }
  }, [scanData]);

  useEffect(() => {
    if (pelletData?.pellets?.length) {
      setGridData((pre) => {
        let i = 0;
        return {
          ...pre,
          pellets: pre.pellets.map((row) => {
            let data = {
              ...row,
              list: pelletData?.pellets.slice(i, i + +row.pellet),
            };
            i += +row.pellet;
            return data;
          }),
        };
      });
    }
  }, [packageData]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PELLETING, route: ROUTES.PELLETING },
          { text: TITLES.CONFIGURE, route: `${ROUTES.PELLETING}/${state?.name}` },
          { text: state?.name, route: '' },
        ]}
      />
      <div className="pellet-scroll ">
        <DetailsBoxPellet
          doneStatus={state?.pallet_process_state === 'done'}
          packageData={packageData}
          packageCount={packageData.packages_count}
          scanData={scanData}
          setScanData={setScanData}
          pelletData={pelletData}
          count={count}
          popUp={popUp}
          setReload={setReload}
          selectedPellet={selectedPellet}
          setSelectedPellet={setSelectedPellet}
          setPackageScanned={setPackageScanned}
        />
        <div className="bg-white p-3 pb-0 package-top-info ">
          <div className="packing-top-btn-div mb-3 d-flex align-items-center justify-content-end"></div>
        </div>
        <ZoomInOut
          gridData={gridData}
          pelletData={pelletData}
          packageData={packageData}
          setPopUp={setPopUp}
          popUp={popUp}
          count={count}
          setSelectedPellet={setSelectedPellet}
          doneStatus={state?.pallet_process_state === 'done'}
          selectedPellet={selectedPellet}
        />
        {popUp && (
          <PopUpBox openPopUpBox={popUp}>
            <BLPList
              setSelectedPellet={setSelectedPellet}
              selectedPellet={selectedPellet}
              setPopUp={setPopUp}
              id={state?.id}
              pelletData={pelletData}
              setReload={setReload}></BLPList>
          </PopUpBox>
        )}
      </div>
    </>
  );
};

const BLPList = ({ id, setReload, pelletData, selectedPellet, setPopUp, setSelectedPellet }) => {
  const [scanData, setScanData] = useState('');
  const [status, setStatus] = useState(0);
  const dispatch = useDispatch();
  const [popUpPellet, setPopUpPellet] = useState([]);
  const handleTransfer = () => {
    if (!status) {
      setStatus(1);
    } else {
      dispatch(setLoaderVisibility(true)),
        palletTransfer({
          data: {
            pallet_id: popUpPellet.pellet_id,
            shipment_id: id,
          },
        })
          .then((response) => {
            setReload(true);
            setPopUp(false);
            popup(POPUP_TYPE.SUCCESS, response.message);
          })
          .catch((error) => {
            dispatch(setLoaderVisibility(false));
            popup(POPUP_TYPE.ERR, error.message);
          });

      setPopUp(false);
    }
  };

  const handleClose = () => {
    setPopUp(false), setSelectedPellet({});
  };

  const statusHandler = () => {
    setStatus((pre) => (pre === 0 ? 1 : 0));
  };
  useEffect(() => {
    if (scanData) {
      if (
        scanData.startsWith('000084') &&
        popUpPellet.packages.map((pack) => pack.blp).includes(scanData)
      ) {
        if (popUpPellet.packages.filter((pack) => pack.blp === scanData)[0].verified === 'placed') {
          dispatch(setLoaderVisibility(true)),
            packagePlacing({
              data: {
                pallet_process_ids: popUpPellet.packages.filter((pack) => pack.blp === scanData)[0]
                  ?.id,
                type: 'verified',
              },
            })
              .then(() => {
                setReload(true);

                popup(POPUP_TYPE.SUCCESS, POPUP_MSG.PACKAGE_VERIFIED(scanData));
              })
              .then(() => {
                setPopUpPellet(
                  pelletData.pellets.filter((pal) => pal.pellet_id === selectedPellet)[0],
                );
              })
              .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup(POPUP_TYPE.ERR, error.message);
              });
          setScanData('');
        } else {
          popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_VERIFIED);
        }
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
        setScanData('');
      }
    }
  }, [scanData]);
  useEffect(() => {
    if (popUpPellet?.packages?.every((pack) => pack.verified === 'verified')) {
      setStatus(1);
    }
  }, [popUpPellet]);
  useEffect(() => {
    if (typeof selectedPellet === 'number') {
      setPopUpPellet(pelletData.pellets.filter((pal) => pal.pellet_id == selectedPellet)[0]);
    }
  }, [selectedPellet, pelletData]);

  return (
    <div>
      <div>
        <div className="d-flex flex-column align-items-end justify-content-end">
          <h3 className="w-100 text-center">{popUpPellet.pellet_name}</h3>
          <hr className='w-100'/>
          <h4 className="d-flex justify-content-end">SKU-{popUpPellet.SKU}</h4>
        </div>
        <div className=" d-flex mb-3 justify-content-between border">
          <div
            onClick={statusHandler}
            className={`${!status ? 'bg-success-subtle' : ''} w-50 text-center p-2 border `}>
            Verification Step
          </div>
          <div
            onClick={statusHandler}
            className={`${status ? 'bg-success-subtle' : ''} w-50 text-center p-2 border `}>
            Transfer Pallet
          </div>
        </div>
      </div>

      {popUpPellet?.packages?.length ? (
        popUpPellet?.packages?.map((blp, index) => {
          return (
            <div key={index}>
              <div
                className={`${blp?.verified === 'verified' ? 'bg-success-subtle' : ' not-verified'} d-flex  w-100 align-items-center justify-content-around p-2 m-1`}>
                <h5>{index + 1}. </h5>
                <h5 className="w-50 ">{blp?.blp}</h5>
              </div>
            </div>
          );
        })
      ) : (
        <div className=" d-flex flex-column align-items-center  justify-content-center">
          {'No packages found'}
        </div>
      )}
      <hr></hr>

      <div className="d-flex justify-content-around">
        {popUpPellet?.packages?.length > 0 && (
          <>
            <div className=" d-flex justify-content-center  visually-hidden">
              <Scanner setScanData={setScanData}></Scanner>
            </div>
            <Button
              clickHandler={handleTransfer}
              className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
              {!status ? 'Skip' : 'Transfer'}
            </Button>
          </>
        )}
        <Button
          clickHandler={handleClose}
          className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
          {BUTTON.CLOSE}
        </Button>
      </div>
    </div>
  );
};
