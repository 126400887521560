import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleBar, Pallet, PopUpBox, Scanner } from '../../common';
import { TITLES, ROUTES, POPUP_TYPE } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { DetailsBoxPallet } from './DetailsBoxPallet';
import { useDebounce } from '../../../hooks';
import { palletPackages, finishPalleting } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { Button } from '../../shared';
// import { createDataBaseInIndexedDb, addDataInIndexDB } from '../../../utils';

export const PalletDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation(location.state);
  const [count, setCount] = useState(0);
  const [palletData, setPalletData] = useState({});
  const [pallet, setPallet] = useState([]);
  const [scanData, setScanData] = useState('');
  const [selectedPallet, setSelectedPallet] = useState({});
  const [step, setStep] = useState(0);
  const [popUp, setPopUp] = useState(false);

  const getPalletPackages = useDebounce(() => {
    palletPackages({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setPalletData(response.data[0]);
        // createDataBaseInIndexedDb();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPalletPackages();
  }, []);

  useEffect(() => {
    if (palletData?.pallets?.length) {
      const data = palletData.pallets.map((pallet) => {
        return {
          pallet_id: pallet.pallet_id,
          packages: [],
          // packages: pallet.packages.map((obj) => obj.name),
          packages_count: pallet.packages_count,
          pallet_name: pallet.pallet_name,
          location: pallet.location_dest_id[0].barcode,
          verified: false,
        };
      });
      setPallet(data);

      // addDataInIndexDB('PalletData', {
      //   id: palletData.picking_id,
      //   package_id: palletData.picking_id,
      //   pallet,
      // });
    }
  }, [palletData]);

  useEffect(() => {
    if (scanData) {
      setSelectedPallet(
        palletData?.pallets?.filter((pallet) =>
          pallet.packages.some((obj) => obj.name === scanData),
        )[0],
      );
    }
  }, [scanData]);

  const handleFinish = () => {
    dispatch(setLoaderVisibility(true));
    finishPalleting({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.PALLETING);
        setStep(0);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PALLETING, route: ROUTES.PALLETING },
          { text: state?.name, route: '' },
        ]}
      />
      <div className="pallet-scroll">
        <DetailsBoxPallet
          doneStatus={state?.state === 'done'}
          palletData={palletData}
          packageCount={palletData.packages_count}
          palletsCount={palletData.pallets_count}
          scanData={scanData}
          setScanData={setScanData}
          pallet={pallet}
          step={step}
          setPallet={setPallet}
          setCount={setCount}
          selectedPallet={selectedPallet}
          setSelectedPallet={setSelectedPallet}
          setStep={setStep}
        />
        <div className="bg-white p-3 package-top-info border-def-up">
          <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
            <h4 className="mb-0">
              Package No- <span>{state?.name}</span>
            </h4>
            <div className="d-flex align-items-center">
              <h4 className="me-3 mb-0">Box Count</h4>
              <div className="number-count d-flex align-items-center justify-content-center">
                {state?.state === 'done'
                  ? `${palletData.packages_count}/${palletData.packages_count}`
                  : `${count}/${palletData.packages_count}`}
              </div>
            </div>
          </div>
          <div className="packing-top-btn-div mb-3 d-flex align-items-center justify-content-end">
            {step === 2 && (
              <Button
                clickHandler={handleFinish}
                className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
                Finish Palleting
              </Button>
            )}
          </div>
        </div>
        <div className="bg-white p-3 pt-0 package-titles-info border-def-down">
          <div className="row custom-row">
            {pallet?.map((pallet) => {
              return (
                <Pallet
                  key={pallet.pallet_id}
                  pallet={pallet}
                  step={step}
                  setPopUp={setPopUp}
                  popUp={popUp}
                  setSelectedPallet={setSelectedPallet}
                  doneStatus={state?.state === 'done'}
                />
              );
            })}
          </div>
        </div>
        {popUp && (
          <PopUpBox openPopUpBox={popUp}>
            <BLPList
              setSelectedPallet={setSelectedPallet}
              selectedPallet={selectedPallet}
              setPopUp={setPopUp}
              setPallet={setPallet}></BLPList>
          </PopUpBox>
        )}
      </div>
    </>
  );
};

const BLPList = ({ selectedPallet, setPopUp, setPallet, setSelectedPallet }) => {
  const [scan, setScan] = useState(false);
  const [scanData, setScanData] = useState('');

  const handleScan = () => {
    if (selectedPallet.packages.every((blp) => blp.verified)) {
      setPallet((pre) =>
        pre.map((pallet) =>
          pallet.pallet_id === selectedPallet.pallet_id ? { ...pallet, verified: true } : pallet,
        ),
      );
      setPopUp(false);
    } else {
      setScan((prev) => !prev);
    }
  };

  useEffect(() => {
    if (scanData) {
      setSelectedPallet((prev) => {
        return {
          ...prev,
          packages: prev.packages.map((blp) => {
            return blp.blp === scanData ? { blp: blp.blp, verified: true } : blp;
          }),
        };
      });
    }
  }, [scanData]);

  return (
    <div>
      {selectedPallet.packages.length ? (
        selectedPallet.packages.map((blp, index) => {
          return (
            <div
              key={blp.blp}
              className={`${blp.verified ? 'bg-success-subtle' : 'not-verified'} d-flex  w-100 align-items-center justify-content-around p-2 m-1`}>
              <h5>{index + 1}. </h5>
              <h5 className="w-50 ">{blp.blp}</h5>
            </div>
          );
        })
      ) : (
        <div className=" d-flex flex-column align-items-center  justify-content-center">
          {'No packages found'}
        </div>
      )}
      <hr></hr>
      <div className="d-flex justify-content-around">
        <Button
          clickHandler={handleScan}
          className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
          {selectedPallet.packages.every((blp) => blp.verified) ? (
            'submit'
          ) : scan ? (
            <Scanner setScanData={setScanData}></Scanner>
          ) : (
            'Scan Packages'
          )}
        </Button>
        <Button
          clickHandler={() => setPopUp(false)}
          className="blue-btn d-flex align-items-center justify-content-center px-5 py-4">
          {'Close'}
        </Button>
      </div>
    </div>
  );
};
