import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Tiles = ({ tileName, Icon, route }) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(route);
  };

  return (
    <div className="col-6" onClick={clickHandler}>
      <div className="w-100 h-100 bg-white db-box flex-column d-flex align-items-center justify-content-center">
        <Icon width={80} height={64} />
        <span className="text-center">{tileName}</span>
      </div>
    </div>
  );
};
