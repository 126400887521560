import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Pellet } from '../../common';
import { Reset } from '../../../assets/icons';

export const ZoomInOut = ({
  gridData,
  setPopUp,
  popUp,
  setSelectedPellet,
  count,
  doneStatus,
  pelletData,
  selectedPellet,
}) => {
  return (
    <div className=" bg-white ">
      <TransformWrapper
        initialPositionX={0}
        initialPositionY={0}
        limitToBounds={false}
        maxScale={1.5}
        minScale={0.3}>
        {({ resetTransform }) => (
          <React.Fragment>
            <div
              className="tools pt-2 ps-2  d-flex justify-content-between "
              onClick={() => resetTransform()}>
              <Reset></Reset>
              <div className="d-flex  align-items-center ">
                <h4 className="me-3 mb-0">Box Count</h4>
                <div className="number-count d-flex align-items-center justify-content-center">
                  {doneStatus === 'done'
                    ? `${pelletData?.packageCount}/${pelletData?.packageCount}`
                    : `${count}/${pelletData?.packageCount}`}
                </div>
              </div>
            </div>
            <TransformComponent wrapperStyle={{ width: '100%' }}>
              <div className="">
                <div className="row custom-row ">
                  {gridData.pellets[0]?.list?.length > 0 &&
                    gridData?.pellets.map((row, index) => (
                      <div key={index} className=" d-flex justify-content-center m-4 gap-3  ">
                        {row?.rowIndex === index
                          ? row.list.map((pellet, index) => (
                              <div key={index} className=" d-flex  justify-content-center">
                                <Pellet
                                  key={pellet.pellet_id}
                                  pellet={pellet}
                                  setPopUp={setPopUp}
                                  popUp={popUp}
                                  setSelectedPellet={setSelectedPellet}
                                  doneStatus={doneStatus}
                                  selectedPellet={selectedPellet}
                                />
                              </div>
                            ))
                          : ''}
                      </div>
                    ))}
                </div>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
};
