import React from 'react';
import { FORM_FIELD, ROUTES } from '../../../constants';
import { warehouseLogout } from '../../../service';
import { popup } from '../../../utils';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const response = await warehouseLogout();
      if (response.status === 'fail') {
        popup('error', response.message || 'Unable to Logout at this moment');
      } else {
        localStorage.clear();
        navigate(ROUTES.LOGIN);
      }
    } catch (error) {
      popup('error', error.message);
    }
  };

  return (
    <div className="collapse navbar-collapse bg-white" id="ProfileSideBar">
      <p className="user-links mb-0" onClick={handleLogout}>
        {FORM_FIELD.LOG_OUT}
      </p>
    </div>
  );
};
