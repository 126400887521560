import { popup } from './popup';
import { POPUP_TYPE, POPUP_MSG } from '../constants';
const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

let request;
export const createDataBaseInIndexedDb = () => {
  //check for support
  if (!idb) {
    popup(POPUP_TYPE.ERR, POPUP_MSG.INDEX_DB_NOT_SUPPORTED);
    return;
  }

  request = idb.open('shakaWear-db', 1);

  request.onerror = function (event) {
    console.error(event);
  };

  request.onupgradeneeded = function (event) {
    if (event.isTrusted) {
      const db = request?.result;

      if (!db.objectStoreNames.contains('PalletData')) {
        const objectStore = db.createObjectStore('PalletData', { keyPath: 'id' });
        objectStore.createIndex('package_id', 'package_id', {
          unique: true,
        });
      }
      if (!db.objectStoreNames.contains('ShipmentData')) {
        const objectStore = db.createObjectStore('ShipmentData', { keyPath: 'id' });
        objectStore.createIndex('shipment_id', 'shipment_id', {
          unique: true,
        });
      }
    }
  };

  request.onsuccess = function (event) {
    popup(POPUP_TYPE.SUCCESS, POPUP_MSG.DATABASE_CREATED);
    console.log(event);
  };
};
export const addDataInIndexDB = (collection, item) => {
  const dbPromise = idb.open('shakaWear-db', 1);
  dbPromise.onsuccess = () => {
    const db = request?.result;
    var tx = db.transaction(collection, 'readwrite');
    var data = tx.objectStore(collection);
    data.add(item);
    return tx.complete;
  };
};

export const UpdateDataInIndexDB = (collection, item) => {
  const dbPromise = idb.open('shakaWear-db', 1);
  dbPromise.onsuccess = () => {
    const db = request.result;
    var tx = db.transaction(collection, 'readwrite');
    var data = tx.objectStore(collection);
    data.put(item);
    return tx.complete;
  };
};
