import React from 'react';
export const PopUpBox = ({ openPopUpBox, children }) => {
  return (
    <div
      className={`popup-position modal custom-modal-doc-cato fade ${openPopUpBox ? 'show' : ''}`}
      aria-hidden={!openPopUpBox}
      tabIndex={-1}
      style={{ display: openPopUpBox ? 'block' : 'none' }}>
      <div className="modal-dialog w-100">
        <div className="modal-content p-3 ">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
