import React from 'react';
import { Tiles } from './Tiles.js';
import { TILES, ROUTES, TITLES } from '../../../constants';
import {
  ShipmentLogo,
  PalletingLogo,
  TransferLogo,
  DeliveryLogo,
} from '../../../assets/icons/index.js';
import { TitleBar } from '../../common/TitleBar.js';

export const DashboardContent = () => {
  return (
    <>
      <TitleBar title={[{ text: TITLES.DASHBOARD, route: '/' }]} />
      <div className="dashboard-block col-10 mx-auto d-flex align-items-center justify-content-center h-100">
        <div className="row db-container w-100">
          <Tiles tileName={TILES.SHIPMENT} Icon={ShipmentLogo} route={ROUTES.SHIPMENT} />
          <Tiles tileName={TILES.PALLETING} Icon={PalletingLogo} route={ROUTES.PALLETING} />
          <Tiles tileName={TILES.TRANSFERS} Icon={TransferLogo} route={ROUTES.TRANSFERS} />
          <Tiles tileName={TILES.DELIVERIES} Icon={DeliveryLogo} route={ROUTES.DELIVERIES} />
        </div>
      </div>
    </>
  );
};
