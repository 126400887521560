import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import {
  Login,
  DashboardContent,
  Shipment,
  PelletList,
  Transfers,
  Deliveries,
  ShipmentDetail,
  DeliveryDetail,
  PelletDetails,
  Pelleting,
  TransferSelector,
} from './components/pages';
import { ROUTES } from './constants';
import { Layout } from './components/common/Layout';
import { getLocalStorageItem } from './utils';

const VendorAuthGuard = () => {
  const authToken = getLocalStorageItem('authToken');
  return authToken === null ? <Login /> : <Navigate to={ROUTES.DASHBOARD} replace />;
};

const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <VendorAuthGuard />,
  },
  {
    path: ROUTES.EMPTY,
    element: <VendorAuthGuard />,
  },
  {
    path: ROUTES.EMPTY,
    element: <Layout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: (
          <VendorAuthGuard>
            <DashboardContent />
          </VendorAuthGuard>
        ),
      },
      {
        path: ROUTES.SHIPMENT,
        element: <Shipment />,
      },
      {
        path: `${ROUTES.SHIPMENT_DETAIL}${ROUTES.URL}`,
        element: <ShipmentDetail />,
      },
      {
        path: ROUTES.PELLETING,
        element: <PelletList />,
      },
      {
        path: `${ROUTES.PELLETING}${ROUTES.URL}`,
        element: <PelletDetails />,
      },
      {
        path: `${ROUTES.PELLET_DETAILS}${ROUTES.URL}`,
        element: <Pelleting />,
      },
      {
        path: ROUTES.TRANSFERS,
        element: <Transfers />,
      },
      {
        path: `${ROUTES.TRANSFER_DETAIL}${ROUTES.URL}`,
        element: <TransferSelector />,
      },
      {
        path: ROUTES.DELIVERIES,
        element: <Deliveries />,
      },
      {
        path: `${ROUTES.DELIVERY_DETAIL}${ROUTES.URL}`,
        element: <DeliveryDetail />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
