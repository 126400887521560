export const deliveryList = [
  {
    reference: '123456',
    to: 'Location A',
    scheduled_date: '2024-08-01',
    actual_date: '2024-08-02',
    source_document: 'SD-001',
    status: 'Waiting for Dispatch',
  },
  {
    reference: '234567',
    to: 'Location B',
    scheduled_date: '2024-08-03',
    actual_date: '2024-08-04',
    source_document: 'SD-002',
    status: 'In Transit',
  },
  {
    reference: '345678',
    to: 'Location C',
    scheduled_date: '2024-08-05',
    actual_date: '2024-08-06',
    source_document: 'SD-003',
    status: 'Received',
  },
  {
    reference: '456789',
    to: 'Location D',
    scheduled_date: '2024-08-07',
    actual_date: '2024-08-08',
    source_document: 'SD-004',
    status: 'Waiting for Dispatch',
  },
  {
    reference: '567890',
    to: 'Location E',
    scheduled_date: '2024-08-09',
    actual_date: '2024-08-10',
    source_document: 'SD-005',
    status: 'In Transit',
  },
  {
    reference: '678901',
    to: 'Location F',
    scheduled_date: '2024-08-11',
    actual_date: '2024-08-12',
    source_document: 'SD-006',
    status: 'Received',
  },
];

export const deliveryOperationList = [
  { product: 'Product 1', demand: '20', quantity: '50', unit: 'Pcs' },
  { product: 'Product 2', demand: '30', quantity: '35', unit: 'Pcs' },
  { product: 'Product 3', demand: '40', quantity: '70', unit: 'Pcs' },
  { product: 'Product 4', demand: '50', quantity: '50', unit: 'Pcs' },
  { product: 'Product 5', demand: '10', quantity: '13', unit: 'Pcs' },
];

export const deliveryDetailOperationList = [
  {
    product: 'Product 1',
    from: 'Location A',
    to: 'Location B',
    blp: '2154358741685428',
    quantity: 10,
  },
  {
    product: 'Product 2',
    from: 'Location C',
    to: 'Location D',
    blp: '2154358741685429',
    quantity: 20,
  },
  {
    product: 'Product 3',
    from: 'Location E',
    to: 'Location F',
    blp: '2154358741685430',
    quantity: 15,
  },
  {
    product: 'Product 4',
    from: 'Location G',
    to: 'Location H',
    blp: '2154358741685431',
    quantity: 25,
  },
  {
    product: 'Product 5',
    from: 'Location I',
    to: 'Location J',
    blp: '2154358741685432',
    quantity: 30,
  },
];
