import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseShipment = async ({ data = {} }) => {
  const url = API.WAREHOUSE_SHIPMENT;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseApproveShipment = async ({ data = {} }) => {
  const url = API.WAREHOUSE_APPROVE_SHIPMENT;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
