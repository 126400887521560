import React, { useEffect, useState } from 'react';
import { SearchBar, Table, TitleBar } from '../../common';
import { ROUTES, DELIVERY_COLUMN, TITLES, POPUP_TYPE } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { warehouseDelivery } from '../../../service';
import { deliveryDataCompiler } from '../../../utils/data_compiler/deliveryDataCompiler';

export const Deliveries = () => {
  const dispatch = useDispatch();
  const [deliveryData, setDeliveryData] = useState([]);

  // API Calling
  const getDeliveryList = useDebounce(() => {
    warehouseDelivery({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setDeliveryData(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDeliveryList();
  }, []);

  return (
    <>
      <TitleBar title={[{ text: TITLES.DELIVERY, route: ROUTES.DELIVERIES }]} />
      <SearchBar />
      <Table
        columns={DELIVERY_COLUMN}
        initialData={deliveryData}
        PAGE_SIZE={10}
        to={ROUTES.DELIVERY_DETAIL}
        compiler={{ use: true, tool: deliveryDataCompiler }}
      />
    </>
  );
};
