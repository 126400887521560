export const transferList = [
  {
    reference: '123456',
    from: 'Warehouse A',
    to: 'Warehouse B',
    scheduled_date: '2023-07-20',
    actual_date: '2023-07-21',
    source_document: 'P00300',
    status: 'Received',
  },
  {
    reference: '654321',
    from: 'Warehouse B',
    to: 'Warehouse C',
    scheduled_date: '2023-07-18',
    actual_date: '2023-07-19',
    source_document: 'P987564',
    status: 'In Transit',
  },
  {
    reference: '112233',
    from: 'Warehouse C',
    to: 'Warehouse D',
    scheduled_date: '2023-07-22',
    actual_date: '2023-07-23',
    source_document: 'P456123',
    status: 'Waiting for Dispatch',
  },
  {
    reference: '445566',
    from: 'Warehouse D',
    to: 'Warehouse E',
    scheduled_date: '2023-07-19',
    actual_date: '2023-07-20',
    source_document: 'P789654',
    status: 'Received',
  },
  {
    reference: '778899',
    from: 'Warehouse E',
    to: 'Warehouse F',
    scheduled_date: '2023-07-21',
    actual_date: '2023-07-22',
    source_document: 'P123789',
    status: 'In Transit',
  },
  {
    reference: '998877',
    from: 'Warehouse F',
    to: 'Warehouse G',
    scheduled_date: '2023-07-23',
    actual_date: '2023-07-24',
    source_document: 'P654321',
    status: 'Waiting for Dispatch',
  },
  {
    reference: '334455',
    from: 'Warehouse G',
    to: 'Warehouse H',
    scheduled_date: '2023-07-17',
    actual_date: '2023-07-18',
    source_document: 'P321456',
    status: 'Received',
  },
  {
    reference: '667788',
    from: 'Warehouse H',
    to: 'Warehouse I',
    scheduled_date: '2023-07-24',
    actual_date: '2023-07-25',
    source_document: 'P654789',
    status: 'In Transit',
  },
  {
    reference: '223344',
    from: 'Warehouse I',
    to: 'Warehouse J',
    scheduled_date: '2023-07-16',
    actual_date: '2023-07-17',
    source_document: 'P789123',
    status: 'Waiting for Dispatch',
  },
  {
    reference: '556677',
    from: 'Warehouse J',
    to: 'Warehouse K',
    scheduled_date: '2023-07-15',
    actual_date: '2023-07-16',
    source_document: 'P987123',
    status: 'Received',
  },
];

export const transferInnerList = [
  {
    product: 'Product 1',
    from: 'Location A',
    to: 'Location B',
    blp: '2154358741685428',
    reserve_quantity: 10,
    done_quantity: 5,
  },
  {
    product: 'Product 2',
    from: 'Location C',
    to: 'Location D',
    blp: '2154358741685429',
    reserve_quantity: 20,
    done_quantity: 20,
  },
  {
    product: 'Product 3',
    from: 'Location E',
    to: 'Location F',
    blp: '2154358741685430',
    reserve_quantity: 15,
    done_quantity: 10,
  },
  {
    product: 'Product 4',
    from: 'Location G',
    to: 'Location H',
    blp: '2154358741685431',
    reserve_quantity: 25,
    done_quantity: 25,
  },
  {
    product: 'Product 5',
    from: 'Location I',
    to: 'Location J',
    blp: '2154358741685432',
    reserve_quantity: 30,
    done_quantity: 20,
  },
];
