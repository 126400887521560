import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const getPalletList = async ({ data = {} }) => {
  const url = API.PALLET_LIST;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const palletPackages = async ({ data = {} }) => {
  const url = API.PALLET_PACKAGES;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const finishPalleting = async ({ data = {} }) => {
  const url = API.FINISH_PALLETING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};
