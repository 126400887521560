export const blpList = [
  {
    BLP: '458746822436871',
    SKU: '04587468',
    Batch: 1,
    SKU_Details: [
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
      {
        SKU: '[MHS025T] Max Heavyweight S/S Black 2XL',
        'Batch No.': '80342 -2',
        Quantity: '40 Unit',
      },
    ],
  },
  {
    BLP: '458746822436872',
    SKU: '04587469',
    Batch: 2,
    SKU_Details: [
      {
        SKU: '[MHS026T] Max Heavyweight S/S White 3XL',
        'Batch No.': '80342 -3',
        Quantity: '50 Unit',
      },
    ],
  },
  {
    BLP: '458746822436871', // Same BLP as the first item
    SKU: '04587470',
    Batch: 3,
    SKU_Details: [
      {
        SKU: '[MHS027T] Lightweight S/S Blue Small',
        'Batch No.': '80342 -4',
        Quantity: '30 Unit',
      },
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436874',
    SKU: '04587471',
    Batch: 4,
    SKU_Details: [
      {
        SKU: '[MHS028T] Max Heavyweight S/S Yellow 4XL Tall',
        'Batch No.': '80342 -5',
        Quantity: '25 Unit',
      },
    ],
  },
  {
    BLP: '458746822436875',
    SKU: '04587472',
    Batch: 5,
    SKU_Details: [
      {
        SKU: '[MHS029T] Max Heavyweight S/S White 2XL',
        'Batch No.': '80342 -6',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436876',
    SKU: '04587473',
    Batch: 6,
    SKU_Details: [
      {
        SKU: '[MHS030T] Max Heavyweight S/S Blue 3XL',
        'Batch No.': '80342 -7',
        Quantity: '20 Unit',
      },
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 2XL',
        'Batch No.': '80342 -2',
        Quantity: '40 Unit',
      },
    ],
  },
  {
    BLP: '458746822436877', // Changed to a unique BLP
    SKU: '04587474',
    Batch: 7,
    SKU_Details: [
      {
        SKU: '[MHS031T] Lightweight S/S Green 4XL Tall',
        'Batch No.': '80342 -8',
        Quantity: '22 Unit',
      },
    ],
  },
  {
    BLP: '458746822436878',
    SKU: '04587475',
    Batch: 8,
    SKU_Details: [
      {
        SKU: '[MHS032T] Lightweight S/S Yellow Small',
        'Batch No.': '80342 -9',
        Quantity: '18 Unit',
      },
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436879',
    SKU: '04587476',
    Batch: 9,
    SKU_Details: [
      {
        SKU: '[MHS033T] Lightweight S/S Red 3XL',
        'Batch No.': '80342 -10',
        Quantity: '15 Unit',
      },
    ],
  },
  {
    BLP: '458746822436878', // Same BLP as Batch 8
    SKU: '04587477',
    Batch: 10,
    SKU_Details: [
      {
        SKU: '[MHS034T] Lightweight S/S Orange 2XL',
        'Batch No.': '80342 -11',
        Quantity: '12 Unit',
      },
    ],
  },
  {
    BLP: '458746822436871', // Same BLP as the first item
    SKU: '04587478',
    Batch: 11,
    SKU_Details: [
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436882',
    SKU: '04587479',
    Batch: 12,
    SKU_Details: [
      {
        SKU: '[MHS035T] Max Heavyweight S/S Green 4XL Tall',
        'Batch No.': '80342 -12',
        Quantity: '28 Unit',
      },
    ],
  },
  {
    BLP: '458746822436883',
    SKU: '04587480',
    Batch: 13,
    SKU_Details: [
      {
        SKU: '[MHS036T] Lightweight S/S Blue Small',
        'Batch No.': '80342 -13',
        Quantity: '32 Unit',
      },
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436884',
    SKU: '04587481',
    Batch: 14,
    SKU_Details: [
      {
        SKU: '[MHS037T] Max Heavyweight S/S Yellow 3XL',
        'Batch No.': '80342 -14',
        Quantity: '21 Unit',
      },
    ],
  },
  {
    BLP: '458746822436885',
    SKU: '04587482',
    Batch: 15,
    SKU_Details: [
      {
        SKU: '[MHS038T] Max Heavyweight S/S Red 2XL',
        'Batch No.': '80342 -15',
        Quantity: '45 Unit',
      },
    ],
  },
  {
    BLP: '458746822436884', // Same BLP as Batch 14
    SKU: '04587483',
    Batch: 16,
    SKU_Details: [
      {
        SKU: '[MHS039T] Lightweight S/S Orange 4XL Tall',
        'Batch No.': '80342 -16',
        Quantity: '19 Unit',
      },
    ],
  },
  {
    BLP: '458746822436887',
    SKU: '04587484',
    Batch: 17,
    SKU_Details: [
      {
        SKU: '[MHS040T] Max Heavyweight S/S Green 3XL',
        'Batch No.': '80342 -17',
        Quantity: '41 Unit',
      },
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
  {
    BLP: '458746822436888',
    SKU: '04587485',
    Batch: 18,
    SKU_Details: [
      {
        SKU: '[MHS041T] Lightweight S/S Yellow Small',
        'Batch No.': '80342 -18',
        Quantity: '24 Unit',
      },
    ],
  },
  {
    BLP: '458746822436889',
    SKU: '04587486',
    Batch: 19,
    SKU_Details: [
      {
        SKU: '[MHS042T] Max Heavyweight S/S Red 3XL',
        'Batch No.': '80342 -19',
        Quantity: '38 Unit',
      },
    ],
  },
  {
    BLP: '458746822436884', // Same BLP as Batch 14 and 16
    SKU: '04587487',
    Batch: 20,
    SKU_Details: [
      {
        SKU: '[MHS024T] Max Heavyweight S/S Black 4XL Tall',
        'Batch No.': '80342 -1',
        Quantity: '36 Unit',
      },
    ],
  },
];
