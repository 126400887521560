import React, { useState, useRef, useCallback, useEffect } from 'react';
import { TableHead, TableBody } from '../shared';
import { useIntersectionObserver } from '../../utils/useIntersectionObserver';
// import { sortingByName, sortingByValue } from '../../utils';
// import { SORTING } from '../../constants';

export const Table = ({
  columns,
  initialData = [],
  PAGE_SIZE,
  colFilter,
  to,
  compiler,
  popUp,
  setPopUp,
  setPopUpData,
  setReload,
}) => {
  // Column filter declaration and logic
  const [selectedColumns, setSelectedColumns] = useState(columns.map((col) => col.name));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const tableEndRef = useRef(null);

  const finalColumns = columns.filter((column) => selectedColumns.includes(column.name));

  useEffect(() => {
    setData(initialData.slice(0, PAGE_SIZE));
    setHasMore(initialData.length > PAGE_SIZE);
  }, [initialData]);
  const loadMoreData = useCallback(() => {
    if (!hasMore || isLoading) return;
    setIsLoading(true);
    setTimeout(() => {
      const newData = initialData.slice(pageNumber * PAGE_SIZE, (pageNumber + 1) * PAGE_SIZE);
      setData((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
      setHasMore(initialData.length > (pageNumber + 1) * PAGE_SIZE);
    }, 300);
  }, [pageNumber, data, initialData, hasMore, isLoading]);

  // to detect when to load more data
  useIntersectionObserver({
    target: tableEndRef,
    onIntersect: loadMoreData,
    enabled: hasMore && !isLoading,
  });

  return (
    <div className="table-container table-1 position-relative mb-3">
      <table className="w-100">
        <TableHead
          columns={columns}
          showColumn={finalColumns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          colFilter={colFilter}
          // sortInfo={sortInfo}
          // setSortInfo={setSortInfo}
        />
        <TableBody
          columns={finalColumns}
          data={data}
          colFilter={colFilter}
          to={to !== '' ? to : undefined}
          compiler={compiler}
          // sortInfo={sortInfo}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
          setReload={setReload}
          // handleSort={handleSort}
        />
      </table>
      <div ref={tableEndRef} className="intersection-observer-trigger" />
    </div>
  );
};
