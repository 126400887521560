import React from 'react';
import { EditIcon, ScanIcon } from '../../../assets/icons';

export const ActionBox = ({
  blpName,
  scan_picked,
  done_quantity,
  reserve_quantity,
  move_line_id,
  setPopUp,
  setPopUpData,
}) => {
  return (
    <div className="td-num">
      {blpName === 'N/A' ? (
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(1);
            setPopUpData({ done_quantity, blpName, scan_picked, reserve_quantity, move_line_id });
          }}
        />
      ) : scan_picked ? (
        <ScanIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(2);
            setPopUpData({ blpName, scan_picked });
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
