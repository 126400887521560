export const shipmentList = [
  {
    shipment_no: 'SHP001',
    shipment_name: 'Electronics',
    expected_date: '2023-07-22',
    actual_date: '2023-07-20',
    status: 'Received',
  },
  {
    shipment_no: 'SHP002',
    shipment_name: 'Clothing',
    expected_date: '2023-07-23',
    actual_date: '2023-07-21',
    status: 'Waiting for Dispatch',
  },
  {
    shipment_no: 'SHP003',
    shipment_name: 'Furniture',
    expected_date: '2023-07-24',
    actual_date: '2023-07-22',
    status: 'In Transit',
  },
  {
    shipment_no: 'SHP004',
    shipment_name: 'Books',
    expected_date: '2023-07-25',
    actual_date: '2023-07-23',
    status: 'Received',
  },
  {
    shipment_no: 'SHP005',
    shipment_name: 'Toys',
    expected_date: '2023-07-26',
    actual_date: '2023-07-24',
    status: 'Waiting for Dispatch',
  },
  {
    shipment_no: 'SHP006',
    shipment_name: 'Groceries',
    expected_date: '2023-07-27',
    actual_date: '2023-07-25',
    status: 'In Transit',
  },
  {
    shipment_no: 'SHP007',
    shipment_name: 'Tools',
    expected_date: '2023-07-28',
    actual_date: '2023-07-26',
    status: 'Received',
  },
  {
    shipment_no: 'SHP008',
    shipment_name: 'Medicines',
    expected_date: '2023-07-29',
    actual_date: '2023-07-27',
    status: 'Waiting for Dispatch',
  },
  {
    shipment_no: 'SHP009',
    shipment_name: 'Stationery',
    expected_date: '2023-07-30',
    actual_date: '2023-07-28',
    status: 'In Transit',
  },
  {
    shipment_no: 'SHP010',
    shipment_name: 'Appliances',
    expected_date: '2023-07-31',
    actual_date: '2023-07-29',
    status: 'Received',
  },
];
