import React from 'react';

export const DetailBox = ({
  colCount,
  colData,
  dataCompiler,
  value,
  setValue,
  clickHandler,
  showSave,
}) => {
  const data = dataCompiler(colData);

  return (
    <>
      <div className="data-content">
        <div className="row">
          {Array.from({ length: colCount }).map((a, index) => (
            <div key={index} className="col-xl-6 col-lg-6 col-12 mb-3 ">
              <div className="bg-white data-box w-100 h-100">
                {data.map((column) =>
                  column.col === 1 + index ? (
                    column.isCusTom ? (
                      column.view({ value, setValue, clickHandler, showSave })
                    ) : (
                      <div key={column.name} className="row mb-3">
                        <div className="col-6 title">{column.name}</div>
                        <div className="col-6">{column.data}</div>
                      </div>
                    )
                  ) : (
                    ''
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
