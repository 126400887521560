import React, { useEffect, useState } from 'react';
import { SearchBar, Table, TitleBar } from '../../common';
import { ROUTES, TRANSFER_COLUMN, TITLES, POPUP_TYPE } from '../../../constants';
import { transferDataCompiler } from '../../../utils/data_compiler/transferCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { warehouseTransfer } from '../../../service';

export const Transfers = () => {
  const dispatch = useDispatch();
  const [transfersData, setTransfersData] = useState([]);

  // API Calling
  const getTransfersList = useDebounce(() => {
    warehouseTransfer({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setTransfersData(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, []);

  return (
    <>
      <TitleBar title={[{ text: TITLES.TRANSFER, route: ROUTES.TRANSFERS }]} />
      <SearchBar />
      <Table
        columns={TRANSFER_COLUMN}
        initialData={transfersData}
        PAGE_SIZE={10}
        to={ROUTES.TRANSFER_DETAIL}
        compiler={{ use: true, tool: transferDataCompiler }}
      />
    </>
  );
};
