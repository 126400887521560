import React from 'react';
import { PelletIcon, Verified } from '../../assets/icons';

export const Pellet = ({ selectedPellet, setSelectedPellet, pellet, doneStatus, setPopUp }) => {
  const completed = pellet.packages_count === pellet.packages.length;
  const popUpHandler = () => {
    setPopUp(true);
    setSelectedPellet(pellet.pellet_id);
  };
  return (
    <div onClick={popUpHandler} className="custom-box mb-3">
      <div
        className={`w-100 ${selectedPellet?.pellet_id === pellet.pellet_id ? 'highlighted-shadow' : ' '}  h-100 bg-white pellet-tiles bg-md`}>
        <div
          className={`top-box ${selectedPellet?.pellet_id === pellet.pellet_id ? 'highlighted' : completed || doneStatus ? 'green-box' : 'blue-box'} text-center w-100`}>
          {`${pellet.pellet_name} `}
        </div>
        <div
          className={`box-bottom p-0 h-100 ${selectedPellet?.pellet_id === pellet.pellet_id ? 'highlighted-bottom' : completed || doneStatus ? 'dark-green' : 'dark-blue'} w-100 d-flex align-items-center justify-content-center`}>
          <div className="txt-box h-100 d-flex align-items-center justify-content-between">
            <div className="number-txt ps-3">
              {completed || doneStatus
                ? `${pellet.packages_count}/${pellet.packages_count}`
                : `${pellet.packages.length}/${pellet.packages_count}`}
            </div>
            <div>{!pellet.verified ? <PelletIcon /> : <Verified />}</div>
            {/* <div>{completed || doneStatus ?  : <PelletIcon />}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
