import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TitleBar, Scanner, SearchBar, AlertBox } from '../../common';
import {
  TITLES,
  ROUTES,
  SHIPMENT_PACKAGE,
  SHIPMENT_STATUS,
  BUTTON,
  SHIPMENT_DETAIL_BOX,
  POPUP_TYPE,
  POPUP_MSG,
} from '../../../constants';
import { ScanIcon } from '../../../assets/icons';
import { ShipmentTile } from './ShipmentTile';
import { Button, StatusBox } from '../../shared';
import { useDebounce } from '../../../hooks';
import { warehouseApproveShipment } from '../../../service';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { transactionIndexDB, popup, idb } from '../../../utils';

export const ShipmentDetail = () => {
  const { state } = useLocation(location?.state);
  const [quantity, setQuantity] = useState(0);
  const [isScanEnabled, setIsScanEnabled] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [inputQuantity, setInputQuantity] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [tilesToReverse, setTilesToReverse] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const startScanning = () => {
    setScanning((prev) => !prev);
  };
  const packages = state?.move_line_ids?.map((pack) => pack?.result_package_id);

  const handleInputChange = (e) => {
    setInputQuantity(e.target.value);
  };

  const handleClick = () => {
    const quantity = +inputQuantity;
    if (!isSaved) {
      if (quantity > 0) {
        setQuantity(quantity);
        setIsScanEnabled(true);
        setIsSubmitEnabled(false);
        setIsSaved(true);
      }
    } else {
      setQuantity(quantity);
      if (tilesToDisplay?.length === quantity) {
        setIsScanEnabled(false);
        setScanning(false);
        setIsSubmitEnabled(true);
      } else {
        setIsScanEnabled(true);
        setIsSubmitEnabled(false);
      }
    }
  };

  const handleRemoveTile = (index) => {
    const remainingTiles = tilesToDisplay?.filter((_, i) => i !== index);
    const removedTile = tilesToDisplay?.filter((_, i) => i === index)[0];
    if (
      remainingTiles?.length &&
      remainingTiles?.filter((tile) => removedTile.name === tile.name).length === 1
    ) {
      const newRemaining = remainingTiles?.map((tile) => {
        return removedTile.name === tile.name ? { ...tile, tileClass: 'green-shade' } : tile;
      });
      setTilesToDisplay(newRemaining.reverse());
    } else {
      setTilesToDisplay(remainingTiles.reverse());
    }
    if (remainingTiles?.length !== quantity) {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  };

  const getOfflineData = ({ collection, item }) => {
    const request = idb.open('shakaWear-db', 1);
    request.onerror = function (event) {
      console.error(event, 'onerror');
    };
    request.onsuccess = function (event) {
      let db = request.result;
      if (event.type === 'success') {
        let tx = db.transaction(collection, 'readonly').objectStore(collection).get(item);
        tx.onerror = function (event) {
          console.error(event, 'onerror');
        };
        tx.onsuccess = (event) => {
          setTilesToDisplay(event.target.result?.data);
          setQuantity(event.target.result?.boxCount);
          if (event.target.result === undefined && state?.state === 'assigned') {
            transactionIndexDB({
              action: 'create',
              collection: 'ShipmentData',
              item: {
                id: state?.id,
                boxCount: quantity,
                shipment_id: state?.id,
                data: tilesToDisplay,
              },
            });
          }
        };
      }
    };
  };

  useEffect(() => {
    getOfflineData({ collection: 'ShipmentData', item: state?.id });
  }, []);

  const handleSubmit = useDebounce(() => {
    const BLP_Data = tilesToDisplay?.map((tile, index) => [index + 1, tile.id]);
    warehouseApproveShipment({
      data: { dispatch_id: state?.id, blp_list: BLP_Data },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.SHIPMENT);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
   useEffect(() => {
    if (scanData) {
      let actualData;
      if (scanData.startsWith('000084') && packages?.map((obj) => obj.name).includes(scanData)) {
        const isRepetitive = tilesToDisplay?.map((obj) => obj.name).includes(scanData);
        if (isRepetitive) {
          actualData = tilesToDisplay?.map((tile) =>
            tile.name === scanData ? { ...tile, tileClass: 'red-shade' } : tile,
          );
          setTilesToDisplay([
            ...actualData,
            {
              id: packages?.filter((pack) => pack.name === scanData)[0].id,
              name: scanData,
              tileClass: isRepetitive ? 'red-shade' : 'green-shade',
            },
          ]);
        } else {
          tilesToDisplay?.length
            ? setTilesToDisplay([
                ...tilesToDisplay,
                {
                  id: packages?.filter((pack) => pack.name === scanData)[0].id,
                  name: scanData,
                  tileClass: isRepetitive ? 'red-shade' : 'green-shade',
                },
              ])
            : setTilesToDisplay([
                {
                  id: packages?.filter((pack) => pack.name === scanData)[0].id,
                  name: scanData,
                  tileClass: isRepetitive ? 'red-shade' : 'green-shade',
                },
              ]);
        }
        setScanData('');
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_SCAN);
        setScanData('');
      }
    }
  }, [scanData]);

  useEffect(() => {
    if (tilesToDisplay?.length > 0 && state?.id) {
      setTilesToReverse(tilesToDisplay);
      transactionIndexDB({
        action: 'update',
        collection: 'ShipmentData',
        item: { id: state?.id, boxCount: quantity, shipment_id: state?.id, data: tilesToDisplay },
      });
    }
    if (tilesToDisplay?.length === quantity) {
      setIsScanEnabled(false);
      setScanning(false);
      setIsSubmitEnabled(true);
    } else {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  }, [tilesToDisplay, quantity]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT },
          { text: state?.name, route: ROUTES.SHIPMENT_DETAIL },
        ]}
      />
      <div className="scroll-div">
        <AlertBox heading={state?.state} />
        <div className="mb-3 new-content-box bg-white px-lg-5 p-4 py-lg-4">
          <div className="col-12">
            <div className="row">
              <div className="col-6 table-text">
                <div className="row">
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[0]}</div>
                  <div className="col-5 mb-4">{state?.name}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[1]}</div>
                  <div className="col-5 mb-4">{state?.partner_id?.name}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[2]}</div>
                  <div className="col-5 mb-4">{state?.scheduled_date.split(' ')[0]}</div>
                </div>
              </div>
              <div className="col-6 table-text">
                <div className="row">
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[3]}</div>
                  <div className="col-5 mb-4">{state?.date_deadline || 'N/A'}</div>
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[4]}</div>
                  <div className="col-5 mb-4">
                    {state?.state.charAt(0).toUpperCase() + state?.state.slice(1)}
                  </div>{' '}
                  <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[5]}</div>
                  <div className="col-5 mb-4">{packages?.length}</div>
                </div>
              </div>
            </div>
            {state?.state === 'assigned' && (
              <div className="col-xl-6 col-lg-7">
                <div className="d-flex align-items-center justify-content-start internal-input-bar-area">
                  <input
                    type="text"
                    className="internal-input-bar"
                    placeholder="Enter Box Quantity"
                    value={inputQuantity}
                    onChange={handleInputChange}
                  />
                  <Button
                    className={`blue-btn px-5 d-flex align-items-center justify-content-center ${inputQuantity === '' || parseInt(inputQuantity) < tilesToDisplay?.length ? 'disabled' : ''}`}
                    clickHandler={handleClick}
                    disabled={
                      inputQuantity === '' || parseInt(inputQuantity) < tilesToDisplay?.length
                    }>
                    {isSaved ? 'Update' : 'Save'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bg-white p-3 package-top-info border-def-up">
          <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
            <div className="d-flex align-items-center">
              <SearchBar width={100} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <h4 className="me-3 ">{SHIPMENT_PACKAGE[1]}</h4>
              <div className="number-count d-flex align-items-center justify-content-center">
                {tilesToDisplay?.length || 0}/{quantity || 0}
              </div>
            </div>
          </div>

          {state?.state === 'assigned' && (
            <div className="d-flex mb-3 justify-content-end">
              {scanning && <Scanner setScanData={setScanData} />}
              <Button
                className={`white-btn d-flex align-items-center justify-content-center ${!isScanEnabled ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={startScanning}
                disabled={!isScanEnabled}>
                {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
              </Button>
              <Button
                className={`blue-btn d-flex align-items-center justify-content-center ${!isSubmitEnabled || quantity === 0 ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={handleSubmit}>
                {BUTTON.SUBMIT}
              </Button>
            </div>
          )}

          <StatusBox status={SHIPMENT_STATUS} />
          <div className="bg-white p-3 pt-0 package-titles-info">
            <div className="row custom-row">
              {tilesToReverse?.reverse()?.map((tile, index) => (
                <ShipmentTile key={index} tile={tile} onRemove={() => handleRemoveTile(index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
