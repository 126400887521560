import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TitleBar, Scanner, SearchBar, AlertBox } from '../../common';
import {
  TITLES,
  ROUTES,
  SHIPMENT_PACKAGE,
  SHIPMENT_STATUS,
  BUTTON,
  SHIPMENT_DETAIL_BOX,
  POPUP_TYPE,
} from '../../../constants';
import { ScanIcon } from '../../../assets/icons';
import { ShipmentTile } from './ShipmentTile';
import { Button, StatusBox } from '../../shared';
import { useDebounce } from '../../../hooks';
import { warehouseApproveShipment } from '../../../service';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';

export const ShipmentDetail = () => {
  const { state } = useLocation(location?.state);
  const [pageState] = useState(state);
  const [quantity, setQuantity] = useState(0);
  const [boxCount, setBoxCount] = useState(0);
  const [isScanEnabled, setIsScanEnabled] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [scannedBLPs, setScannedBLPs] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [inputQuantity, setInputQuantity] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startScanning = () => {
    setScanning((prev) => !prev);
  };

  const handleSave = (newQuantity) => {
    setQuantity(newQuantity);
    setBoxCount(0);
    setTilesToDisplay([]);
    setScannedBLPs([]);
    setIsScanEnabled(true);
    setIsSubmitEnabled(false);
    setIsSaved(true);
  };

  const handleUpdateQuantity = (newQuantity) => {
    setQuantity(newQuantity);
    if (tilesToDisplay.length === newQuantity) {
      setIsScanEnabled(false);
      setScanning(false);
      setIsSubmitEnabled(true);
    } else {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  };

  const handleInputChange = (e) => {
    setInputQuantity(e.target.value);
  };

  const handleSaveClick = () => {
    const quantity = parseInt(inputQuantity, 10);
    if (quantity > 0) {
      handleSave(quantity);
    }
  };

  const handleUpdateClick = () => {
    const quantity = parseInt(inputQuantity, 10);
    handleUpdateQuantity(quantity);
  };

  const handleClick = () => {
    if (!isSaved) {
      handleSaveClick();
    } else {
      handleUpdateClick();
    }
  };

  const handleRemoveTile = (index) => {
    const updatedTiles = tilesToDisplay.filter((_, i) => i !== index);
    const updatedBLPs = scannedBLPs.filter((blp, i) => i !== index);

    setTilesToDisplay(
      updatedTiles.map((tile) => ({
        ...tile,
        tileClass:
          updatedBLPs.filter((blp) => blp === tile.BLP).length > 1 ? 'red-shade' : 'green-shade',
      })),
    );
    setScannedBLPs(updatedBLPs);
    handleBoxScanned(-1);

    if (updatedTiles.length !== quantity) {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  };

  const handleBoxScanned = (increment = 1) => {
    setBoxCount((prevCount) => {
      const newCount = prevCount + increment;
      if (newCount === quantity) {
        setIsScanEnabled(false);
        setScanning(false);
        setIsSubmitEnabled(true);
      }
      return newCount;
    });
  };

  const handleSubmit = useDebounce(() => {
    warehouseApproveShipment({
      data: { dispatch_id: pageState.id, blp_list: scannedBLPs },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.SHIPMENT);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if (scanData) {
      const newTile = { BLP: scanData };
      const isRepetitive = scannedBLPs.includes(newTile.BLP);

      const updatedTiles = [
        ...tilesToDisplay,
        { ...newTile, tileClass: isRepetitive ? 'red-shade' : 'green-shade' },
      ];

      const updatedBLPs = [...scannedBLPs, newTile.BLP];
      setTilesToDisplay(
        updatedTiles.map((tile) => ({
          ...tile,
          tileClass:
            updatedBLPs.filter((blp) => blp === tile.BLP).length > 1 ? 'red-shade' : 'green-shade',
        })),
      );
      setScannedBLPs(updatedBLPs);
      handleBoxScanned();
      setScanData('');
    }
  }, [scanData]);

  useEffect(() => {
    if (tilesToDisplay.length === quantity) {
      setIsScanEnabled(false);
      setIsSubmitEnabled(true);
    } else {
      setIsScanEnabled(true);
      setIsSubmitEnabled(false);
    }
  }, [tilesToDisplay, quantity]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT },
          { text: pageState?.name, route: ROUTES.SHIPMENT_DETAIL },
        ]}
      />
      <div className="scroll-div">
        <AlertBox heading={pageState.state} subHeading={pageState.state} />
        <div className="mb-3 new-content-box bg-white px-lg-5 p-4 py-lg-4">
          <div className="row">
            <div className="col-xl-6 col-lg-5 table-text">
              <div className="row">
                <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[0]}</div>
                <div className="col-5 mb-4">{pageState.name}</div>
                <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[1]}</div>
                <div className="col-5 mb-4">{pageState.partner_id?.name}</div>
                <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[2]}</div>
                <div className="col-5 mb-4">{pageState.scheduled_date.split(' ')[0]}</div>
                <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[3]}</div>
                <div className="col-5 mb-4">{pageState.date_deadline || 'N/A'}</div>
                <div className="col-4 mb-4">{SHIPMENT_DETAIL_BOX[4]}</div>
                <div className="col-5 mb-4">
                  {pageState.state.charAt(0).toUpperCase() + pageState.state.slice(1)}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="d-flex align-items-center justify-content-start internal-input-bar-area">
                <input
                  type="text"
                  className="internal-input-bar"
                  placeholder="Enter Box Quantity"
                  value={inputQuantity}
                  onChange={handleInputChange}
                />
                <Button
                  className={`blue-btn px-5 d-flex align-items-center justify-content-center ${inputQuantity === '' || parseInt(inputQuantity) < boxCount ? 'disabled' : ''}`}
                  clickHandler={handleClick}
                  disabled={inputQuantity === '' || parseInt(inputQuantity) < boxCount}
                >
                  {isSaved ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 package-top-info border-def-up">
          <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
            <h4 className="mb-0">{SHIPMENT_PACKAGE[0]}</h4>
            <div className="d-flex align-items-center">
              <h4 className="me-3 mb-0">{SHIPMENT_PACKAGE[1]}</h4>
              <div className="number-count d-flex align-items-center justify-content-center">
                {scannedBLPs.length}/{quantity}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <SearchBar />
            <div className="d-flex justify-content-end">
              {scanning && <Scanner setScanData={setScanData} />}
              <Button
                className={`white-btn d-flex align-items-center justify-content-center ${!isScanEnabled ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={startScanning}
                disabled={!isScanEnabled}
              >
                {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
              </Button>
              <Button
                className={`blue-btn d-flex align-items-center justify-content-center ${(!isSubmitEnabled || quantity===0) ? 'disabled' : ''} px-4 mx-2`}
                clickHandler={handleSubmit}>
                {BUTTON.Submit}
              </Button>
            </div>
          </div>
          <StatusBox status={SHIPMENT_STATUS} />
          <div className="bg-white p-3 pt-0 package-titles-info">
            <div className="row custom-row">
              {tilesToDisplay.map((tile, index) => (
                <ShipmentTile key={index} tile={tile} onRemove={() => handleRemoveTile(index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
