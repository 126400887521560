import { Login } from './login/Login';
import { Logout } from './login/Logout';
import { DashboardContent } from './dashBoard/DashboardContent';
import { PalletList } from './palleting/PalletList';
import { PalletDetails } from './palleting/PalletDetails';
import { Transfers } from './transfers/Transfers';
import { Deliveries } from './deliveries/Deliveries';
import { Shipment } from './shipment/Shipment';
import { ShipmentDetail } from './shipment/ShipmentDetail';
import { TransfersDetail } from './transfers/TransfersDetail';
import { DeliveryDetail } from './deliveries/DeliveryDetail';
export {
  Login,
  Logout,
  DashboardContent,
  Shipment,
  ShipmentDetail,
  PalletList,
  PalletDetails,
  Transfers,
  TransfersDetail,
  Deliveries,
  DeliveryDetail,
};
