import React, { useEffect, useState } from 'react';
import { SearchBar, Table, TitleBar } from '../../common';
import { POPUP_TYPE, ROUTES, SHIPMENT_COLUMN, TITLES } from '../../../constants';
import { shipmentDataCompiler } from '../../../utils/data_compiler/shipmentCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { warehouseShipment } from '../../../service';

export const Shipment = () => {
  const dispatch = useDispatch();
  const [shipmentData, setShipmentData] = useState([]);

  // API Calling
  const getShipmentList = useDebounce(() => {
    warehouseShipment({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setShipmentData(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getShipmentList();
  }, []);

  return (
    <>
      <TitleBar title={[{ text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT }]} />
      <SearchBar />
      <Table
        columns={SHIPMENT_COLUMN}
        initialData={shipmentData}
        PAGE_SIZE={10}
        colFilter={false}
        to={ROUTES.SHIPMENT_DETAIL}
        compiler={{ use: true, tool: shipmentDataCompiler }}
      />
    </>
  );
};
