import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseTransfer = async ({ data = {} }) => {
  const url = API.WAREHOUSE_TRANSFERS;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseTransferQtyUpdate = async ({ data = {} }) => {
  const url = API.WAREHOUSE_TRANSFERS_QTY_UPDATE;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehousePackageScan = async ({ data = {} }) => {
  const url = API.WAREHOUSE_PACKAGE_SCAN;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseValidatePicking = async ({ data = {} }) => {
  const url = API.WAREHOUSE_VALIDATE_PICKING;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};

export const warehouseCheckUncheck = async ({ data = {} }) => {
  const url = API.WAREHOUSE_CHECK_UNCHECK;
  const response = await fetchData({ body: { ...data }, method: METHODS.POST, url });
  return response;
};


