import * as React from 'react';
const ShipmentLogo = (props) => (
  <svg
    width={25}
    height={26}
    viewBox="0 0 80 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1334 64C22.5517 64 26.1334 60.4183 26.1334 56C26.1334 51.5817 22.5517 48 18.1334 48C13.7151 48 10.1334 51.5817 10.1334 56C10.1334 60.4183 13.7151 64 18.1334 64Z"
      fill="#4B5564"
    />
    <path
      d="M63.4667 64C67.8849 64 71.4667 60.4183 71.4667 56C71.4667 51.5817 67.8849 48 63.4667 48C59.0484 48 55.4667 51.5817 55.4667 56C55.4667 60.4183 59.0484 64 63.4667 64Z"
      fill="#4B5564"
    />
    <path
      d="M81.352 27.4478L68.8 14.8958V29.3332H82.1334C82.1334 28.6265 81.852 27.9478 81.352 27.4478Z"
      fill="#4B5564"
    />
    <path
      d="M63.4667 32V13.3333H55.4667V45.3587C57.6987 43.6773 60.464 42.6667 63.4667 42.6667C70.8187 42.6667 76.8 48.648 76.8 56H79.4667C80.9387 56 82.1333 54.8053 82.1333 53.3333V34.6667H66.1333C64.6613 34.6667 63.4667 33.472 63.4667 32Z"
      fill="#4B5564"
    />
    <path
      d="M47.4667 0H-0.533285C-2.00528 0 -3.19995 1.19467 -3.19995 2.66667V53.3333C-3.19995 54.8053 -2.00528 56 -0.533285 56H4.80005C4.80005 48.648 10.7814 42.6667 18.1334 42.6667C25.4854 42.6667 31.4667 48.648 31.4667 56H50.1334V2.66667C50.1334 1.19467 48.9387 0 47.4667 0ZM33.352 25.8853L25.352 33.8853C24.8307 34.4067 24.1494 34.6667 23.4667 34.6667C22.784 34.6667 22.1027 34.4067 21.5814 33.8853C20.54 32.844 20.54 31.156 21.5814 30.1147L25.0294 26.6667H10.1334C8.66138 26.6667 7.46671 25.472 7.46671 24C7.46671 22.528 8.66138 21.3333 10.1334 21.3333H25.0294L21.5814 17.8853C20.54 16.844 20.54 15.156 21.5814 14.1147C22.6227 13.0733 24.3107 13.0733 25.352 14.1147L33.352 22.1147C34.3934 23.156 34.3934 24.844 33.352 25.8853Z"
      fill="#4B5564"
    />
  </svg>
);
export default ShipmentLogo;
