import { API, METHODS } from '../constants';
import { fetchData } from '../utils';

export const warehouseLogin = ({ data }) => {
  const url = API.WAREHOUSE_LOGIN;
  const response = fetchData({ body: data, method: METHODS.POST, url });
  return response;
};

export const warehouseLogout = async () => {
  const url = API.WAREHOUSE_LOGOUT;
  const response = await fetchData({ body: {}, method: METHODS.POST, url });
  return response;
};
