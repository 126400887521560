import React, { useState } from 'react';
import { Reference, Sort1 } from '../../assets/icons';
import { Dropdown2 } from './Dropdown2';

export const TableHead = ({
  columns,
  showColumn,
  selectedColumns,
  setSelectedColumns,
  colFilter,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const clickHandler = () => {
    setShowDropDown((prev) => !prev);
  };

  // Filter columns if colFilter is false
  const filteredColumns = colFilter
    ? showColumn
    : showColumn.filter((column) => column.name !== 'checkbox');

  return (
    <thead className="mb-4">
      <tr>
        {filteredColumns.map((column, colIndex) => (
          <th key={colIndex} className={column.classNameTH}>
            {column.name === 'checkbox' ? (
              <div className="dropdown">
                <button
                  className="dropdown-toggle border-0 d-flex align-items-center justify-content-center"
                  type="button"
                  id="dropdownMenuButton1"
                  onClick={clickHandler}
                >
                  <Reference />
                </button>
                <Dropdown2
                  list={columns}
                  show={showDropDown}
                  setShow={setShowDropDown}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </div>
            ) : (
              <div className="text-btn-div d-flex align-items-center justify-content-start">
                <span>{column.name}</span>
                {column.sort && (
                  <button className="p-0 border-0 d-flex align-items-center justify-content-center sort-btn">
                    <Sort1 />
                  </button>
                )}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
