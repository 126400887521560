import React from 'react';

export function Button({
  className,
  children,
  text = '',
  type = 'button',
  id = '',
  disabled,
  clickHandler = () => {},
}) {
  return (
    <button className={className} onClick={clickHandler} type={type} id={id} disabled={disabled}>
      {text ? text : children}
    </button>
  );
}
