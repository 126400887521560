import React from 'react';
import { PalletIcon, Right, YellowRight } from '../../assets/icons';
export const Pallet = ({ pallet, doneStatus, setSelectedPallet, step, popUp, setPopUp }) => {
  const completed = pallet.packages.length === pallet.packages_count;

  const popUpHandler = () => {
    if (step === 1) {
      setSelectedPallet({});
      if (!popUp) setPopUp(true);
      setSelectedPallet({
        ...pallet,
        packages: pallet.packages.map((blp) => {
          return { verified: false, blp };
        }),
      });
    }
  };

  return (
    <div onClick={popUpHandler} className="col-xl-2 col-lg-4 col-6 custom-box mb-3">
      <div className="w-100 h-100 bg-white pellet-tiles bg-md">
        <div
          className={`top-box ${completed || doneStatus ? 'green-box' : 'blue-box'} text-center w-100`}>
          {`${pallet.pallet_name} / ${pallet.location ? pallet.location.replace('/', '-') : ''}`}
        </div>
        <div
          className={`box-bottom p-0 h-100 ${completed || doneStatus ? 'dark-green' : 'dark-blue'} w-100 d-flex align-items-center justify-content-center`}>
          <div className="txt-box h-100 d-flex align-items-center justify-content-between">
            <div className="number-txt">
              {doneStatus
                ? `${pallet.packages_count}/${pallet.packages_count}`
                : `${pallet?.packages?.length}/${pallet.packages_count}`}
            </div>
            <div>
              {pallet.verified ? (
                <YellowRight />
              ) : completed || doneStatus ? (
                <Right />
              ) : (
                <PalletIcon />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
