import React, { useEffect, useState } from 'react';
import { ScanIcon } from '../../../assets/icons';
import { Scanner } from '../../common';
import { popup } from '../../../utils';
import { POPUP_MSG, POPUP_TYPE } from '../../../constants';
import { packagePlacing } from '../../../service';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { Button } from '../../shared';

export const DetailsBoxPellet = ({
  doneStatus,
  scanData,
  setScanData,
  pelletData,
  packageData,
  selectedPellet,
  setReload,
  count,
  setSelectedPellet,
  setPackageScanned,
  popUp,
}) => {
  const [scanState, setScanState] = useState(false);
  const dispatch = useDispatch();

  const handleScanStart = () => {
    setScanState((pre) => !pre);
    setScanData('');
  };

  const handleTransfer = () => {
    if (Object.entries(selectedPellet).length) {
      const pallet = packageData.filter((pack) => pack.package_id.name === scanData)[0];
      if (pallet?.state === 'assigned') {
        dispatch(setLoaderVisibility(true)),
          packagePlacing({
            data: {
              pallet_process_ids: pallet.id,
              type: 'placed',
            },
          })
            .then((response) => {
              setReload(true);
              popup(
                POPUP_TYPE.SUCCESS,
                POPUP_MSG.PACKAGE_PLACED(scanData, response.data[0].state, pallet.pallet_id.name),
              );
              setSelectedPellet({});
            })
            .catch((error) => {
              dispatch(setLoaderVisibility(false));
              popup(POPUP_TYPE.ERR, error.message);
            });
      } else if (pallet?.state === 'placed') {
        popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_PLACED), setSelectedPellet({});
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.PLACED_AND_VERIFIED);
        setSelectedPellet({});
      }
      setScanState(true), setScanData('');
      setPackageScanned('');
    }
  };
  useEffect(() => {
    if (popUp) {
      setScanState(false);
    }
  }, [popUp]);
  return (
    <div className="bg-white top-data-box mb-3 py-3 px-4">
      <div className="row">
        <div className="col-xl-5 col-12">
          {(!doneStatus || count < pelletData.packageCount) && (
            <div className="d-flex align-items-center justify-content-start  ">
              <div className="btn-set-pellet h-50 flex-column d-flex align-items-center  me-5">
                <div
                  onClick={handleScanStart}
                  className="white-btn w-100 d-flex align-items-center justify-content-center px-4 py-2">
                  {scanState ? 'Stop Scan' : 'Scan Box'}
                  <ScanIcon />
                </div>
                <Button
                  clickHandler={handleTransfer}
                  className={`${!Object.keys(selectedPellet)?.length && 'disabled'} blue-btn w-100 d-flex align-items-center justify-content-center px-4 py-2`}>
                  {selectedPellet ? 'Confirm Transfer' : 'Scan Again'}
                </Button>
              </div>

              {scanState ? <Scanner setScanData={setScanData} /> : ''}
              {Object.keys(selectedPellet).length > 0 && (
                <div className="d-flex align-items-center">
                  <InfoPellet pellet={pelletData.pellets} selectedPellet={selectedPellet} />
                  <h4 className="m-3  text-success">&quot;{selectedPellet?.SKU}&quot;</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const InfoPellet = ({ selectedPellet }) => {
  return (
    <>
      {selectedPellet ? (
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <div className="pellet-color-box flex-column h-50 d-flex align-items-center ">
            <div className="box-top h-25 blue-box ">
              <h4 className="text-center pt-2 ">{selectedPellet.pellet_name}</h4>
            </div>
            <div className="box-bottom h-25 pellet-info dark-blue d-flex align-items-center justify-content-center">
              <div className="text-center">
                {`${selectedPellet?.packages?.length}/ ${selectedPellet.packages_count}`}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        'Wrong BLP Scanned'
      )}
    </>
  );
};
