import React from 'react';
import { Image } from '../shared';
import { logo, userImg } from '../../assets/img';
import { Arrow } from '../../assets/icons';
import PropTypes from 'prop-types';
import { Logout } from '../pages';
import { LOCAL_STORAGE } from '../../constants';
import { getLocalStorageItem } from '../../utils';

export const Header = () => {
  const user = getLocalStorageItem(LOCAL_STORAGE.USERNAME);
  return (
    <header className="header bg-white">
      <div className="d-flex align-items-center">
        <div className="main-logo d-flex align-items-center justify-content-center">
          <Image src={logo} alt="logo" width="148" height="53" />
        </div>
        <div className="header-content w-100">
          <div className="d-flex align-items-center justify-content-end w-100">
            <button
              className="d-flex align-items-center justify-content-center border-0 p-0 bg-white icon-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#ProfileSideBar"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div className="d-flex align-items-center">
                {!user ? (
                  <Image src={userImg} width="32" height="32" className="user-img" />
                ) : (
                  <div className="display-row-text">
                    {getLocalStorageItem(LOCAL_STORAGE.USERNAME)}
                  </div>
                )}
                <Arrow />
              </div>
            </button>
            <Logout />
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  userName: PropTypes.string,
};

export default Header;
