export function palletListCompiler(data) {
  let finalData = [];
  data.map((shipment) => {
    let shipmentData = {
      url: shipment.name.replaceAll('/', '_'),
      shipment_id: shipment.name,
      status: shipment.state,
      packages:shipment.packages_count,
      pallets:shipment.pallets_count,
      expected_date: shipment.scheduled_date,
      deadline: shipment.date_deadline,
      data: shipment,
    };
    finalData.push(shipmentData);
  });
  return finalData;
}
