import * as React from 'react';
const ScanIcon = (props) => (
  <svg
    width={16}
    height={14}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.2673 0.62532H12.4253V5.35342H10.2673V0.62532Z" fill="#167DDC" />
    <path
      d="M9.93316 5.35443V0.62532H2.34632C1.17569 0.62532 0.222778 1.57823 0.222778 2.74886V3.22988C0.222778 4.40051 1.17569 5.35342 2.34632 5.35342L9.93316 5.35443ZM5.5362 4.05114H2.58734C2.00202 4.05114 1.52607 3.57519 1.52607 2.98988C1.52607 2.40456 2.00202 1.92861 2.58734 1.92861H5.5362V4.05114Z"
      fill="#167DDC"
    />
    <path d="M5.65369 6.90785H6.50837L6.8304 5.68861H5.999L5.65369 6.90785Z" fill="#167DDC" />
    <path
      d="M3.94323 11.7099C3.92703 11.7686 3.94323 11.8304 3.98576 11.8739L4.74931 12.6375V13.3747H1.10779V11.7787L2.83538 5.68861H5.65159L3.94323 11.7099Z"
      fill="#167DDC"
    />
    <path
      d="M14.8445 0.737725C14.7797 0.672915 14.6734 0.672915 14.6085 0.737725C14.5437 0.803548 14.5437 0.908864 14.6085 0.974687C15.1473 1.51342 15.443 2.22836 15.443 2.98988C15.443 3.7514 15.1463 4.46633 14.6085 5.00507C14.5437 5.07089 14.5437 5.17621 14.6085 5.24102C14.6409 5.27342 14.6835 5.28962 14.727 5.28962C14.7696 5.28962 14.8121 5.27342 14.8455 5.24102C15.446 4.64051 15.7772 3.84051 15.7772 2.98988C15.7772 2.13924 15.446 1.33924 14.8445 0.737725Z"
      fill="#167DDC"
    />
    <path
      d="M14.1205 1.82432C14.0557 1.75849 13.9494 1.75849 13.8836 1.82432C13.8178 1.89014 13.8188 1.99545 13.8836 2.06026C14.396 2.57267 14.396 3.40609 13.8836 3.91849C13.8188 3.98432 13.8188 4.08963 13.8836 4.15545C13.916 4.18786 13.9585 4.20406 14.0021 4.20406C14.0446 4.20406 14.0871 4.18786 14.1205 4.15545C14.7636 3.51242 14.7636 2.46735 14.1205 1.82432Z"
      fill="#167DDC"
    />
  </svg>
);
export default ScanIcon;
