import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const TableBody = ({ columns, data, colFilter, to, compiler, popup, setPopUp, setPopUpData, setReload }) => {
  const navigate = useNavigate();
  const finalData = compiler?.use ? compiler.tool(data) : data;
  const handleRowClick = ({ data, url }) => {
    if (to) {
      navigate(`${to}/${url}`, { state: data });
    }
  };

  const filteredColumns = colFilter
    ? columns
    : columns.filter((column) => column.name !== 'checkbox');

  return (
    <tbody>
      {finalData.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className={`table-row ${row.scan_picked ? 'scan-picked': ''}`}
          style={{ cursor: to ? 'pointer' : 'default' }}
          onClick={to ? () => handleRowClick({ data: row.data || row, url: row.url }) : undefined}
        >
          {filteredColumns.map((column, colIndex) => (
            <td key={colIndex} className={column.classNameTD}>
              {column.isCustom ? (
                column.customFormatter(
                  column.dataField.reduce((acc, field) => {
                    acc[field] = row[field];
                    return {...acc, popup, setPopUp, setPopUpData, setReload}
                  }, {}),
                )
              ) : (
                <div
                  className={`${typeof row[column.dataField] === 'number' ? 'td-num' : 'td-text'}`}
                >
                  {row[column.dataField]}
                </div>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  colFilter: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  to: PropTypes.string,
};

export default TableBody;
